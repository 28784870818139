/* font-family: 'Merriweather', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Open Sans', sans-serif;
font-family: 'Passion One', sans-serif;
font-family: 'Poppins', sans-serif; */

.container{
    width: 100%;
    min-height: 100dvh;
    background: linear-gradient(to bottom,rgba(0,0,0,0.7),rgba(0,0,0,0.6)),url("../staticImages/backgroundImage.png");
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.header{
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:100%;
    padding: 2rem 3rem 2rem 3rem;
    background-color: rgba(255, 255, 255, 0.676);
    filter: blur(0.2px);
    z-index: 99;
}

.header_title h3{
    font-size: 2.5rem;
    color: #333;
    font-weight: bold;
    font-family: 'Montserrat',sans-serif;
    letter-spacing: 0.1rem;
}

.menu_links{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;
}

.link{
    color: #333;
    font-size: 2rem;
}

/* MOBILE MENU CSS */

.menu_button{
    display: none;
    visibility: hidden;
}

.mobile_menu_container{
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    min-height:100dvh;
    background-color: rgba(255, 255, 255, 0.90);
    z-index: 98;
    transform: translateX(100%); 
    transition: transform 0.3s ease-in-out; 
}

.mobile_menu_container.menu_visible{
    display: block;
    transform: translateX(0);
}

.mobile_menu_sub_container{
    margin-top: 15rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 0 3rem 0 3rem;
}

.sub_container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap:2rem;
    width: 100%;
    padding: 5rem 3rem 0 3rem;
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%,-50%); */
}

/* MAIN IMAGE CONTAINER */

.main_image_container{
    position: relative;
    width: 100%;
}

.main_image{
    background: linear-gradient(to right,rgba(0,0,0,0.6),rgba(0,0,0,0.6)),url("../staticImages/backgroundImage.png");
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 50rem;
}

.image_details{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
}

.image_details .title{
    font-size: 2.5rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: white;
    text-transform: uppercase;
}

.image_details .btn{
    border: 0.1rem solid white;
    padding: 1rem 2rem 1rem 2rem;
    color: white;
    background-color: transparent;
    border-radius: 0.2rem;
    text-transform: uppercase;
    font-size: 1.5rem;
    font-family: 'Montserrat',sans-serif;
    transition: all 0.3s;
}

.btn:hover{
    background-color: black;
    border: 0.1rem solid black;
}

.btn:active{
    transform: scale(1.1);
}

/* VIDEOS SECTION CSS */

.videos_section{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
}

.videos_section_title h3{
    font-size: 2rem;
    color: #333;
    font-family: 'Montserrat',sans-serif;
    font-weight: bold;
}


.carousel_container{
    width: 100%;
    /* background-color: white; */
    overflow: hidden;
    padding: 0 0 3rem 0;
}

.carousel_container > span {
    display: none !important;
    visibility: hidden !important;
}

@media screen and (max-width:900px){
    .header .menu_links{
        display: none;
        visibility: hidden;
    }

    .header_title h3{
        font-size: 1.5rem;
    }

    .menu_button{
        display: block;
        visibility: visible;
    }

    .menu_button .icon{
        font-size: 1.5rem;
        color: #333;
        cursor: pointer;
    }

    .main_image{
        height: 35rem;
    }
}

@media screen and (max-width:650px){
    .sub_container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap:2rem;
        width: 100%;
        padding: 10rem 0.5rem 0 0.5rem;
    }
    .image_details .title{
        font-size: 2rem;
    }
    .image_details .btn{
        font-size: 1.5rem;
    }
}


@media screen and (max-width:650px){
   
    .image_details .title{
        font-size: 1.5rem;
    }
    .image_details .btn{
        font-size: 1rem;
    }
}

/* LOADING */

.loading_container{
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
}
.loader {
    width: 48px;
    height: 48px;
    border: 5px solid #FFF;
    border-bottom-color: #FF3D00;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    } 

    /* VIDEO CONTAINER */

    /* .video_container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    } */

    .video_content{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        width: 100%;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translate(-50%,0);
        color: white;
    }

    .video_content h3{
        text-align: center;
        color: white;
        font-size: 2rem;
        font-family: 'Montserrat',sans-serif;
    }

    .btn_play{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
        padding: 0.5rem 2rem 0.5rem 2rem;
        background-color: rgb(211, 21, 21);
        color: white;
        font-size: 1.5rem;
        font-family: 'Montserrat',sans-serif;
        box-shadow: 0 1rem 2rem rgba(0,0,0,0.4);
        border-radius: 0.3rem;
    }

    .image_carousel{
        width: 30rem;
        height: 70rem;
        object-fit: contain;
    }

    .video_container{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
        width: 40rem;
        min-height: 30rem;
        object-fit: contain;
        position: relative;
        cursor: pointer;
    }

    .video_container_2{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
    }

    @media screen and (max-width:400px) {
        .video_container{
            width: 30rem;
        }
    }

    @media screen and (max-width:450px) {
        .video_container{
            width: 35rem;
        }
    }

    .video_content_1{
        position: absolute;
        bottom: 1rem;
        left: 2%;
        transform: translate(0%,0);
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 1rem;
    }
    .video_content_1 h3{
        color: white;
        font-size: 2rem;
        font-family: 'Montserrat',sans-serif;
        font-weight: bold;
    }

    @media screen and (max-width:700px) {
        .video_content_1 h3{
            color: white;
            font-size: 1.5rem;
            font-family: 'Montserrat',sans-serif;
        }
    }

    @media screen and (max-width:700px){
        .video_content h3{
            font-size: 1.5rem;
        }
        .btn_play{
            font-size: 1rem;
        }
    }


    .img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        overflow: hidden;
    }
.container{
    background-color: #E8E6E6;
    width: 100%;
    min-height: 100dvh;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.sub_container{
    min-height: 26rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 0 4rem 0;
}
.sub_container h3{
    font-size: 5rem;
    font-family: 'Montserrat',sans-serif;
    font-weight: bold;
    letter-spacing: 0.2rem;
    color: #333;
    text-align: center;
}
.terms_container{
    width: 80%;
    height: 40rem;
    background-color: #fff;
}

@media screen and (max-width:1000px){
    .terms_container{
        width: 100%;
        height: 40rem;
        background-color: #fff;
    }
    .sub_container{
        min-height: 12rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        padding: 0 0 4rem 0;
    }
    .sub_container h3{
        font-size: 1.5rem;
    }
    
}
.episode_container{
 width: 50rem;
 display: flex;
 justify-content: space-between;
 align-items: center;
}
.episode{
    color: white;
    font-size: 1.5rem;
    font-family: 'Montserrat',sans-serif;
    text-transform: uppercase;
}
.btn{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem 1rem 1rem 1rem;
    background-color: rgb(211, 21, 21);
    color: white;
    font-size: 1rem;
    font-family: 'Montserrat',sans-serif;
    box-shadow: 0 1rem 2rem rgba(0,0,0,0.4);
    border-radius: 0.3rem;
}
.icon{
    font-size: 1rem;
    color: white;
}

@media screen and (max-width:800px){
    .episode_container{
        width: 30rem;
    }
}

.video{
    width: 50rem !important;
    height: auto !important;
}

@media screen and (max-width:600px) {
    .video{
        width: 40rem !important;
    }
}

@media screen and (max-width:400px) {
    .video{
        width: 35rem !important;
    }
}
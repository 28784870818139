@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
:root {
  margin: 0;
  padding: 0;
  text-decoration: none;
  list-style-type: none;
  /* overflow-x: hidden !important; */
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.swiper-container {
  position: relative !important;
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.swiper-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}


.swiper-slide.swiper-slide-active {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}

.swiper-slide.swiper-slide-prev{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}
.swiper-slide.swiper-slide-next{
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
}

.swiper-pagination-bullet{
  display: block !important;
  visibility: visible !important;
}

.swiper-slide-shadow{
  background: unset !important;
}

@media screen and (max-width:550px){
  .swiper-pagination{
    position: absolute !important;
    bottom: 0% !important;
    left: 50% !important;
    transform: translate(-50%,0%) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    height: 3rem;
  }
}